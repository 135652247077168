.configuration {
    background: #FFF;
    text-align: left;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.configuration h1 {
    font-size: 2.4rem;
    margin: 1.6rem
}

.configuration h2 {
    font-size: 2.0rem;
    margin: 1.6rem
}

.configuration #font-family {
    margin-left: 1.6rem;
}

.configuration .template input {
    display: none;
}

.configuration .template label {
    display: block;
    padding: 5%;
}

.configuration .template label i {
    display: inline-block;
    color: #666;
    margin-bottom: 5%;
}

.configuration .template input[type="radio"]:checked+label {
    background-color: #EEE;
}

.configuration .labels {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
}

.preview {
    overflow: hidden;
    height: 12rem;
}

.preview:hover {
    cursor: pointer;
    background-color: rgba(180, 220, 220, 0.5);
}

.preview .ele {
    transform: scale(.25) !important;
    transform-origin: top left;
}