.dot {
    padding: .6rem;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    background-color: silver;
    margin-right: .8rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.dot.hide {
    visibility: hidden;
}