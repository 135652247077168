.btn-delete {
    display: inline-block;
    opacity: 0;
    background: #fff;
    color: #444;
    font-weight: bold;
    border: 1px solid silver;
    border-radius: 100px;
    position: absolute;
    top: -1.3rem;
    height: 2.6rem;
    width: 2.6rem;
    left: -1.3rem;
    transition: opacity 0.4s;
}

.btn-delete:active {
    background: #ddd;
    color: #222;
}

.list-project-container {
    display: flex;
    overflow-x: scroll;
}

.list-project-header {
    display: flex;
    align-items: center;
}

.list-project-header h2 {
    flex-grow: 1;
}

.list-project #grid {
    display: flex;
    list-style-type: none;
    padding: .4rem;
}