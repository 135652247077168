.sequence {
    display: inline-flex;
    min-width: 6rem;
    min-height: 4rem;
    padding: .6rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: .4rem;
    font-size: 2.6rem;
    font-weight: bold;
    margin-right: .8rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

.sequence-colored-dark {
    color: #000000;
}

.sequence-colored-light {
    color: #FFFFFF;
}