.empty-project-layout {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.empty-project-layout a {
  text-decoration: none;
}

.empty-project-item {
  display: flex;
  border-radius: 0.8rem;
  transition-property: transform background-color;
  transition-duration: 0.5s;
  color: #222;
  text-decoration: none;
  padding-bottom: 1.6rem;
}

.empty-project-item:hover {
  transform: scale(1.05);
  background-color: #EEE;
  cursor: pointer;
}

.empty-project-item:active {
  background-color: rgba(180, 220, 220, 0.5);
}

.empty-project-item img {
  width: 12.8rem;
  height: 12.8rem;
  grid-column: 1;
  grid-row: 1;
  margin: .8rem 3.2rem;
  opacity: .8;
  transition-property: opacity;
  transition-duration: 0.5s;
}

.empty-project-item:hover img {
  opacity: 1;
}

.empty-cell-text {
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.empty-project-item h1 {
  margin: 1.6rem;
  text-decoration: none;
}

.empty-project-item span {
  display: inline-block;
  padding: 0rem 1.6rem;
  color: #444;
}