.toolbar {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 4px #4444;
  height: 100%;
  align-items: center;
}

.toolbar h1 {
  flex-grow: 1;
  margin: 0;
  text-align: left;
  font-size: 2rem
}

.toolbar .button {
  background-color: transparent;
  margin: 0 .8rem;
  border-radius: 100rem;
  padding: .8rem;
}

.toolbar .button:hover,
.toolbar .button:focus {
  background-color: rgba(180, 220, 220, 0.5);
}