.creator-layout {
  display: grid;
  grid-template-areas: "creator-title creator-title" "creator-one creator-two";
  align-content: center;
  padding-bottom: 7.2rem;

  background-image: url("../illus/undraw_web_developer_p3e5.svg"), url("../illus/undraw_videographer_nnc7.svg");
  background-position: left 1.6rem center, right 1.6rem center;
  background-size: 19.2rem;
  background-repeat: no-repeat;
  transition-property: background-size background-position;
  transition-duration: 0.5s;
}

.creator-layout:hover {
  background-size: 16.4rem;
}

.creator-layout h2 {
  grid-area: creator-title;
}

.creator-item {
  display: block;
  width: 25.6rem;
  transition-property: transform;
  transition-duration: 0.5s;
  color: #222;
  text-decoration: none;
  text-align: center;
}

.creator-item:hover {
  transform: scale(1.15);
}

.creator-item img {
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 12.8rem;
}

.creator-name {
  display: inline-block;
  padding-top: 1.6rem;
  padding-bottom: 0.8rem;
  font-weight: bold;
}

.creator-one {
  grid-area: creator-one;
  justify-self: end;
}

.creator-two {
  grid-area: creator-two;
  justify-self: start;

}