.projects-card {
    position: relative;
    display: flex;
    align-content: flex-end;
    flex-direction: column;
    width: 24rem;
    min-width: 12rem;
    margin: 1.2rem;
    padding: .8rem;
    padding-bottom: 0;
    text-align: center;
    border: 1px solid silver;
    background-color: #FFF;
}

.projects-card:hover {
    outline: .3rem solid #CCC;
    cursor: pointer;
}

.projects-card:hover .btn-delete {
    opacity: 1;
}

.projects-card .img-project-container {
    min-height: 16rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: #000;
}

.projects-card .img-project-container .img-project-cover {
    width: 100%;
    display: block;
}

.projects-card .img-project-container .img-project-link {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
    padding: 6px;
    border-radius: 30px;
    margin: 6px;
    background-color: rgba(255, 255, 255, 0.6);
}

.projects-card span {
    padding: 1.6rem;
    display: block;
    color: #000000BB
}

.projects-card a {
    text-decoration: none;
}