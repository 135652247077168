/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.display {
  display: block;
}

/* Modal Content/Box */
.modal-content {
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, .98);
  margin: 4% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 62rem;
  text-align: left;
}

#form-img {
  width: 100%;
}

#form-meta-datas {}

#form-sequence,
#form-action,
#label,
#periode,
#fx {
  border-radius: 2px;
  background-color: #EEE;
  border: none;
  margin-bottom: .8rem;
  font-family: "Courier New", Courier, monospace;
  font-size: 2.4rem;
}

#form label {
  padding: 1rem;
}

#form-action {
  width: 100%;
}

#form-sequence {
  width: 6rem;
}

#periode {
  width: 14rem;
}

#label {
  width: 16rem;
}

.photogram-form-container {
  margin-bottom: 1rem;
  text-align: center;
  position: relative;
}

.photogram-form-container input {
  display: none;
}

.photogram-form-container #photogram-label {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  opacity: .7;
  transition: opacity .4s;
}

.photogram-form-container #photogram-label:hover {
  opacity: 1;
}

.photogram-form-container #photogram-label img {
  width: 100%;
}

.photogram-form-container #photogram-label em {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2228;

}

.button-container {
  display: block;
  width: 100%;
  position: absolute;
  min-height: 4.8rem;
  bottom: .4rem;
  background: rgba(0, 0, 0, 0.25);
  text-align: start;
}

.button-container button {
  background: none;
  border: none;
  color: white;
  min-height: 4.8rem;
  font-size: 1.4rem;
  text-transform: uppercase;
}

.button-container button:hover,
.button-container button:focus {
  background: rgba(180, 220, 220, 0.5);
}